<template>
    <div>
        <b-progress show-progress :max="max" class="my-3">
            <b-progress-bar variant="primary" :value="done" />
        </b-progress>
    </div>
</template>

<script>
export default {
    name: 'BulkDownloadProgress',
    props: {
        sessionIds: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    computed: {
        max () {
            return this.sessionIds.length
        },
        done () {
            return this.sessionIds.filter(item => item.exported).length
        }
    }
}
</script>
