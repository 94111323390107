<template>
    <div>
        <div class="container">
            <h1>{{$t('contracts.heading')}}</h1>
            <p>{{$t('contracts.subtitle')}}</p>
        </div>
        <div class="container-fluid mt-4">
            <div class="p-4 bg-white shadow rounded-sm">
                <div class="mb-4">
                    <b-btn
                        variant="primary"
                        size="sm"
                        class="mr-5 mb-2"
                        :disabled="selected.length === 0 || !socketConnected || bulkDownloadRunning"
                        @click.prevent="onDownloadSelectionArchive"
                    >
                        {{$t('contracts.downloadSelected')}} <b-badge variant="light">{{selected.length}}</b-badge>
                    </b-btn>
                    <b-btn
                        v-for="item in sessionStatuses"
                        :key="item.status"
                        :variant="(item.status === sessionStatus) ? 'warning' : 'primary'"
                        @click.prevent="onSetStatus(item.status)"
                        :disabled="sessionsLoading"
                        class="mr-2 mb-2"
                        size="sm"
                    >
                        <span class="iconify" data-icon="mdi:funnel"></span> {{item.title}}
                    </b-btn>
                </div>

                <div
                    v-if="bulkDownloadState !== null"
                    class="row mb-4"
                >
                    <div class="col-12 col-lg-6">
                        <div class="p-4 border broder-gray">
                            <h5>{{$t((bulkDownloadRunning) ? 'contracts.bulkDownloadProcessing' : 'contracts.bulkDownloadDone')}}</h5>
                            <bulk-download-progress
                                v-if="!bulkDownloadState.completed"
                                :session-ids="generatedSessionIds"
                            />
                            <div v-else>
                                <b-btn
                                    variant="primary"
                                    size="sm"
                                    class="mr-2 mb-2"
                                    target="_blank"
                                    rel="noopener"
                                    :href="`/api/dochub/brokers/${$user.izdocsCompanyId}/document-sessions/bulk-downloads/${bulkDownloadState.id}`"
                                >
                                    <span class="iconify" data-icon="mdi:download"></span> {{$t('contracts.download')}}
                                </b-btn>
                                <b-btn
                                    variant="light"
                                    size="sm"
                                    class="mr-2 mb-2"
                                    @click.prevent="bulkDownloadState = null"
                                >
                                    <span class="iconify" data-icon="mdi:close"></span> {{$t('contracts.close')}}
                                </b-btn>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-end">
                    <div>
                        <form-input-select
                            :id="'limit'"
                            :label="$t('contracts.limitOfRecords')"
                            :options="limitOptions"
                            v-model="limit"
                            :size="'sm'"
                        />
                    </div>
                </div>
                <iz-documents-data-table
                    :dataset="sessions"
                    :archive-loading="archiveLoading"
                    :loading="sessionsLoading"
                    @detail="onDetail"
                    @download="onDownloadArchive"
                    v-model="selected"
                />
            </div>
        </div>
    </div>
</template>

<script>
import IzDocumentsDataTable from '../../Components/IzDocumentsDataTable.vue'
import BulkDownloadProgress from './Components/BulkDownloadProgress.vue'

export default {
    name: 'ContractsAccepted',
    components: {
        IzDocumentsDataTable,
        BulkDownloadProgress
    },
    props: {
        sessionId: {
            required: false,
            default: null
        }
    },
    data () {
        return {
            sessions: [],
            sessionsLoading: true,
            sessionStatus: 'export',
            archiveLoading: false,
            selectionArchiveLoading: false,
            selected: [],
            socketConnected: false,
            bulkDownloadState: null,
            limit: 500
        }
    },
    computed: {
        limitOptions () {
            return [100, 250, 500, 1000, 2000, 5000, 10000, 25000]
                .map(value => ({ value, text: value }))
        },
        generatedSessionIds () {
            return (this.bulkDownloadState !== null) ? this.bulkDownloadState.sessions : []
        },
        bulkDownloadRunning () {
            return (this.bulkDownloadState !== null) ? this.bulkDownloadState.running : false
        },
        sessionStatuses () {
            return [
                {
                    status: 'export',
                    title: this.$t('contracts.status.export')
                },
                {
                    status: 'exported',
                    title: this.$t('contracts.status.exported')
                },
                {
                    status: 'rejected',
                    title: this.$t('contracts.status.rejected')
                }
            ]
        }
    },
    mounted () {
        this.loadSessions()
        this.connectSocket()
    },
    beforeDestroy () {
        this.leaveAccountChannel()
        this.$socket.disconnect()
    },
    watch: {
        socketConnected (newValue) {
            if (newValue) {
                this.joinAccountChannel()
            }
        },
        limit () {
            this.$nextTick(() => {
                this.loadSessions()
            })
        }
    },
    methods: {
        joinAccountChannel () {
            this.$nextTick(() => {
                this.$socket.emit('joinAccountChannel')
            })
        },
        leaveAccountChannel () {
            this.$nextTick(() => {
                this.$socket.emit('leaveAccountChannel')
            })
        },
        connectSocket () {
            this.$socket.connect()
        },
        async onDownloadSelectionArchive () {
            if (this.largeArchiveLoading) {
                return
            }
            this.selectionArchiveLoading = true
            try {
                await this.$api.dochub.createBulkDownload(this.$user.izdocsCompanyId, this.selected.map(item => item.id))
                this.$notify.success(this.$t('contracts.bulkDownloadCreated'))
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('contracts.downloadError'))
            } finally {
                this.$nextTick(() => {
                    this.selectionArchiveLoading = false
                })
            }
        },
        async onDownloadArchive (session) {
            if (this.archiveLoading) {
                return
            }
            this.archiveLoading = true
            try {
                await this.$api.dochub.downloadSessionArchive(this.$user.izdocsCompanyId, session.id)
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('contracts.downloadError'))
            } finally {
                this.$nextTick(() => {
                    this.archiveLoading = false
                })
            }
        },
        loadSessions () {
            this.sessionsLoading = true
            this.$api.dochub.getDocumentSessions(this.$user.izdocsCompanyId, this.sessionStatus, this.limit)
                .then((response) => {
                    this.sessions = [...response.data]
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('contracts.loadingError'))
                }).finally(() => {
                    this.$nextTick(() => {
                        this.sessionsLoading = false
                    })
                })
        },
        onDetail (row) {
            this.$router.push({ name: 'ContractsDetail', params: { sessionId: row.id } }).catch(() => {})
        },
        onSetStatus (status) {
            this.sessionStatus = status
            this.$nextTick(() => {
                this.loadSessions()
            })
        }
    },
    sockets: {
        connect () {
            this.socketConnected = true
            this.joinAccountChannel()
        },
        connect_error () {
            this.socketConnected = false
        },
        disconnect () {
            this.socketConnected = false
        },
        bulkDownloadStatus (bulkDownload) {
            this.bulkDownloadState = bulkDownload
        }
    }
}
</script>

<i18n>
{
    "cz": {
        "contracts": {
            "heading": "Odeslané smlouvy",
            "subtitle": "Zde naleznete seznam všech smluv které byly odeslány z portálu pro agenty ke zpracování.",
            "loadingError": "Nepodařilo se načíst seznam sezení.",
            "downloadError": "Nepodařilo se vygenerovat archiv ke stažení.",
            "downloadSelected": "Stáhnout vybrané",
            "bulkDownloadCreated": "Byl vytvořen požadavek na vygenerování archivu ke stažení. Vyčkejte prosím.",
            "bulkDownloadProcessing": "Probíhá generování archivu ke stažení..",
            "bulkDownloadDone": "Archiv je připraven ke stažení",
            "download": "Stáhnout",
            "close": "Zavřít",
            "limitOfRecords": "Limit max. počtu záznamů",
            "status": {
                "export": "Ke zpracování",
                "exported": "Zpracované",
                "rejected": "Zamítnuté",
                "discard": "Neplatné / Ke smazání"
            }
        }
    },
    "en": {}
}
</i18n>
